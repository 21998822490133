@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_Th.ttf') format('truetype');
  font-weight: thin;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_ThIt.ttf') format('truetype');
  font-weight: thin;
  font-style: italic;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_Md.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_MdIt.ttf') format('truetype');
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_Lt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_LtIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_BdIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_Blk.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('assets/fonts/ATTAleckSans_BlkIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Jubilat';
  src: url('assets/fonts/Jubilat-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jubilat';
  src: url('assets/fonts/Jubilat-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Jubilat';
  src: url('assets/fonts/Jubilat-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Jubilat';
  src: url('assets/fonts/Jubilat-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
